import React, { useEffect, useState } from 'react';
import FetchData from '../../fetch-api/Apifetch';
import { LIST_DEPARTMENT, ADD_DEPARTMENT, DELETE_DEPARTMENT, UPDATEDEPARTMENT, LIST_SUBDEPARTMENT, ADD_SUBDEPARTMENT, DELETE_SUBDEPARTMENT, UPDATESUBDEPARTMENT, GET_SUBDEPT_BYDEPT, SYNCDEPARTMENT } from '../../utils/ApiRoute';
import { Shimmer } from '../../common/Shimmer';
import "./common.css";
import { toast } from 'react-toastify';
import PagesHeadings from '../../common/PagesHeadings'
import CommonModal from '../../common/CommonModal';
import SuccessModal from '../../common/SuccessModal';
import { t } from 'i18next';
const Department = () => {
  const [Department, setDepartment] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingnew, setLoadingNew] = useState(true);
  const [newDepartmentTitle, setNewDepartmentTitle] = useState('');
  const [editId, seteditId] = useState()
  const [subeditId, setsubeditId] = useState()
  const [filterOption, setFilterOption] = useState();
  const [language, setlanguage] = useState(localStorage.getItem('lang'))
  const [subDepartment, setsubDepartment] = useState([])
  const [DepartName, setDepartName] = useState()
  const [modalstate, setModalstate] = useState()
  const [ModalView, setModalView] = useState(false)
  const [ModalAddView, setModalAddView] = useState(false)
  const [modalContent, setModalContent] = useState({ icon: 0, desc: '' });
  const [responsive, setresponsive] = useState(false)

  const [FilterData, setFilterData] = useState({
    from: '',
    to: ''
  });
  const [subdepartmentData, setsubdepartmentData] = useState('')

  useEffect(() => {
    GetDepartment();
    ListSubDepartment()
  }, []);

  const GetDepartment = async () => {
    setLoading(true);
    try {
      const res = await FetchData(`${LIST_DEPARTMENT}?filter=${filterOption}&from_date=${FilterData.from}&to_date=${FilterData.to}`,
        'GET',
        null,
        false,
        false
      );
      if (res.status) {
        console.log(res.data);
        setDepartment(res.data);
        setDepartName(res.data[0])
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    subDepartmentByDepartment()
  }, [DepartName]);

  const ListSubDepartment = async () => {
    try {
      const res = await FetchData(LIST_SUBDEPARTMENT, 'GET', null, false, false)
      console.log(res.data);
      setsubDepartment(res.data)
    } catch (err) {
      console.log(err);
    }
  }

  const setdepartmentEdit = (items) => {
    setModalstate(0)
    setNewDepartmentTitle(items.title)
    seteditId(items.id)
  }
  const setSubdepartmentEdit = (items) => {
    setModalstate(1)
    setsubdepartmentData(items.SubDepartmentTitle)
    setsubeditId(items.SubDepartmentId)
  }

  const DepartmentUpdate = async () => {
    if (modalstate == 0) {
      const requestData = {
        title: newDepartmentTitle
      };
      try {
        const res = await FetchData(UPDATEDEPARTMENT + editId, 'PUT', JSON.stringify(requestData), false, false)
        console.log(res);
        setModalAddView(true)
        setModalContent({ icon: 1, desc: 'Updated Department Successfully.' });
        GetDepartment();
        seteditId(null)
        closeModal();

      } catch (err) {
        console.log(err);
      }
    } else if (modalstate == 1) {
      const requestData = {
        title: subdepartmentData,
        dept_id: DepartName.id
      };
      try {
        const res = await FetchData(UPDATESUBDEPARTMENT + subeditId, 'PUT', JSON.stringify(requestData), false, false)
        console.log(res);
        setModalAddView(true)
        setModalContent({ icon: 1, desc: 'Updated Sub Department Successfully.' });
        ListSubDepartment()
        closeModal()
      } catch (err) {
        console.log(err);
      }
    }
  }

  const addDepartment = async () => {
    if (modalstate == 0) {
      const requestData = {
        title: newDepartmentTitle
      };

      console.log(requestData);

      try {
        const res = await FetchData(ADD_DEPARTMENT, 'POST', JSON.stringify(requestData), false, false);
        if (res.status) {
          console.log('Department added successfully:', res.data);
          GetDepartment();
          setModalAddView(true)
          setModalContent({ icon: 1, desc: 'Added Department' });
          closeModal(); // Close the modal
        } else {
          console.log('Failed to add department:', res.message);
        }
      } catch (err) {
        console.log(err);
      }
    } else if (modalstate == 1) {
      const requestData = {
        title: subdepartmentData,
        dept_id: DepartName.id
      };
      try {
        const res = await FetchData(ADD_SUBDEPARTMENT, 'POST', JSON.stringify(requestData), false, false);
        ListSubDepartment()
        closeModal()
        // SuccessModal({icon:1,desc:'Added Sub Department',setShowModal:setModalAddView,showModal:ModalAddView})
        setModalAddView(true)
        setModalContent({ icon: 1, desc: 'Added Sub Department' });

      } catch (err) {
        console.log(err);
      }
    }
  }

  const deleteDepartment = async (id) => {
    // try {
    //   const res = await FetchData(`${DELETE_DEPARTMENT}/${id}`, 'DELETE', null, false, false);
    //   if (res.status) {
    //     console.log('Department deleted successfully:', res.data);
    //     toast.success('Department Deleted Successfully.')
    //     seteditId()
    //     setModalAddView(true)
    //     setModalContent({ icon: 1, desc: 'Deleted Sub Department' });
    //     GetDepartment();
    //             // setDepartment((prevDepartments) => prevDepartments.filter(department => department.id !== id));
    //   } else {
    //     console.log('Failed to delete department:', res.message);
    //   }
    // } catch (err) {
    //   console.error('Error during department deletion:', err);
    // }
    const requestData = {
      title: newDepartmentTitle,
      status: 1
    };
    try {
      const res = await FetchData(UPDATEDEPARTMENT + editId, 'PUT', JSON.stringify(requestData), false, false)
      console.log(res);
      setModalAddView(true)
      setModalContent({ icon: 1, desc: 'Deleted Department Successfully.' });
      GetDepartment();
      seteditId(null)
      closeModal();

    } catch (err) {
      console.log(err);
    }
  };

  const ShowsubModaldelete = (items) => {
    setModalView(true)
    setModalstate(1)
    setsubeditId(items.SubDepartmentId)
    setsubdepartmentData(items.SubDepartmentTitle)

  }
  const ShowModaldelete = (item) => {
    setModalView(true)
    setModalstate(0)
    seteditId(item.id)
    setNewDepartmentTitle(item.title)

  }
  const DeleteSubDepartment = async (id) => {
    // try {
    //   const res = await FetchData(DELETE_SUBDEPARTMENT + id, 'DELETE', null, false, false)
    //   console.log(res);
    //   setsubeditId('')
    //   setModalAddView(true)
    //   setModalContent({ icon: 1, desc: 'Deleted Sub Department' });

    // } catch (err) {
    //   console.log(err);
    // }
    // ListSubDepartment()
    const requestData = {
      status: 1,
      title: subdepartmentData,
      dept_id: DepartName.id
    };
    try {
      const res = await FetchData(UPDATESUBDEPARTMENT + subeditId, 'PUT', JSON.stringify(requestData), false, false)
      console.log(res);
      setModalAddView(true)
      setModalContent({ icon: 1, desc: 'Deleted Sub Department Successfully.' });
      ListSubDepartment()
      closeModal()
    } catch (err) {
      console.log(err);
    }

  }

  const subDepartmentByDepartment = async () => {
    setLoadingNew(true)
    try {
      const res = await FetchData(GET_SUBDEPT_BYDEPT + `?dept_id=${DepartName?.id}`, "GET", null, false, false);
      console.log(res.data);
      setsubDepartment(res.data);
      setLoadingNew(false)
    } catch (err) {
      console.error(err);
      setLoadingNew(false)
    }
    setLoadingNew(false)
  }


  const closeModal = () => {
    const modal = document.getElementById('exampleModal');
    const editmodal = document.getElementById('exampleModaledit');
    if (modal || editmodal) {
      modal.querySelector('.close').click();
    }
    setNewDepartmentTitle('')
    setsubdepartmentData('')
  };

  const InputField = (title, name, type, value, onchange) => {
    return (
      <>
        <label htmlFor="recipient-name" className="col-form-label" style={{ fontSize: 14 }}>{title}</label>
        <input
          type={type}
          className="form-control"
          placeholder={t('Enter_title')}
          id="recipient-name"
          name={name}
          value={value}
          style={{ fontSize: 14 }}
          onChange={(e) => onchange(e.target.value)}
        />
      </>
    )
  }
  const [rotate, setRotate] = useState(false);
  const [rotate1, setRotate1] = useState(false);
  const handleReload = () => {
    setRotate(true)
    syncData()
  };
  const handleReload1 = () => {
    setRotate1(true)
    syncData()
  };

  const syncData = async () => {
    try {
      const res = await FetchData(SYNCDEPARTMENT, 'GET', null, false, false)
      console.log(res);
      GetDepartment();
      ListSubDepartment()
      setRotate(false)
      setRotate1(false)
    }
    catch (err) {
      console.log(err);
      GetDepartment();
      ListSubDepartment()
      setRotate(false)
      setRotate1(false)

    }
  }
  return (
    <>
      <PagesHeadings PageHeader={t("Department_list")} insidehead={t("User_Management")} />
      {loading ? (
        <Shimmer />
      ) : (
        <div className="md:col-span-12 lg:col-span-7 xl:col-span-7 col-span-12 row mx-auto">
          <div className={`box !p-[20px] col-lg-5 shadow-sm ${responsive ? 'd-sms-none' : 'col-sms-12'}`} >
            <div className="box-header !border-b-0 !p-0 flex justify-between">
              <h4 className={`box-title mb-2`}>{t("Department_list")}</h4>
              <div className="d-flex align-items-center gap-3"><img src='/images/icons/reload.png' role='button'
                className={rotate ? 'rotating' : ''}
                onClick={() => handleReload()}
                style={{ width: '25px', height: '25px' }} />
                <button type="button" className="new-project-btn btn-wfm btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo" onClick={() => setModalstate(0)}>
                  {t('Add')} {t('Department')}
                </button>
              </div>
            </div>
            <div className="card my-2" style={{ height: '65vh', overflowY: 'scroll' }}>
              <div className='table-responsive'>
                <table className="table whitespace-nowrap min-w-full mb-0">
                  <thead className='bg-primary-subtle'>
                    <tr className='border-b bg-primary-subtle'>
                      <th scope="col" className={`${language == 'ar' ? 'text-end':'text-start'} bg-primary-subtle`}> {t('Numbers')}</th>
                      <th scope="col" className={`${language == 'ar' ? 'text-end':'text-start'} bg-primary-subtle`} style={{ width: '40%' }}> {t('Department')}</th>
                      <th scope="col" className={`${language == 'ar' ? 'text-end':'text-start'} bg-primary-subtle`}> {t('Action')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Department.map((item, index) => (
                      <tr className={`border-b border-primary/10 py-3 ${DepartName.id === item.id ? 'bg-lightblue' : ''}`} key={index} role='button' onClick={() => { setDepartName(item); setresponsive(true) }}>
                        <th scope="col" className={`${language == 'ar' ? 'text-end':'text-start'} py-3 ${DepartName.id === item.id ? 'bg-lightblue' : ''}`}> {index + 1}</th>
                        <th scope="col" className={`${language == 'ar' ? 'text-end':'text-start'} py-3 ${DepartName.id === item.id ? 'bg-lightblue' : ''}`}> {item.title}</th>
                        <th className={`py-3 ${language == 'ar' ? 'text-end':'text-start'} ${DepartName.id === item.id ? 'bg-lightblue' : ''}`}>
                          <div className='hstack flex gap-3 text-[.9375rem] '>
                            <a aria-label='anchor' role='button' className='ti-btn ti-btn-sm ti-btn-info !rounded-full bg-info-subtle' data-toggle="modal" data-target="#exampleModaledit" onClick={() => setdepartmentEdit(item)}><i class='ri-edit-line text-info'></i></a>
                            <a aria-label='anchor' role='button' className='ti-btn ti-btn-sm ti-btn-danger !rounded-full bg-danger-subtle' onClick={() => ShowModaldelete(item)}><i class='ri-delete-bin-line text-danger'></i></a>
                          </div>
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className={`box !p-[20px] col-lg-7 shadow-sm ${!responsive ? 'd-sms-none' : 'col-sms-12'}`} >
            <a className='d-lg-none' onClick={() => setresponsive(false)}><i class="ri ri-close-large-fill" aria-hidden="true"></i></a>
            <div className="box-header !border-b-0 !p-0 flex justify-between">
              <h4 className={`box-title mb-2`}>{t('Sub_Department_of')} {DepartName?.title}</h4>
              <div className="d-flex align-items-center gap-3"><img src='/images/icons/reload.png' role='button'
                className={rotate1 ? 'rotating' : ''}
                onClick={() => handleReload1()}
                style={{ width: '25px', height: '25px' }} />
                <button type="button" className="new-project-btn  btn-wfm btn-sm" data-toggle="modal" data-target="#exampleModal" data-whatever="@mdo" onClick={() => setModalstate(1)}>
                  {t('Add')} {t('Sub_Department')}
                </button>
              </div>
            </div>
            {loadingnew ? (
              <Shimmer />
            ) : (
              <div className="card my-2" style={{ height: '65vh', overflowY: 'scroll' }}>
                <div className='table-responsive'>
                  <table className="table whitespace-nowrap min-w-full mb-0">
                    <thead className='bg-primary-subtle'>
                      <tr className='border-b bg-primary-subtle'>
                        <th scope="col" className={`${language == 'ar' ? 'text-end':'text-start'} bg-primary-subtle`}> {t('Numbers')}</th>
                        <th scope="col" className={`${language == 'ar' ? 'text-end':'text-start'} bg-primary-subtle`}>{t('Sub_Department')}</th>
                        <th scope="col" className={`${language == 'ar' ? 'text-end':'text-start'} bg-primary-subtle`}> {t('Action')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* .filter(item => item?.Departmentid === DepartName?.id) */}
                      {subDepartment?.map((item, index) => (
                        <tr className='border-b border-primary/10 py-3' key={index}>
                          <th scope="col" className={`${language == 'ar' ? 'text-end':'text-start'} py-3`}> {index + 1}</th>
                          <th scope="col" className={`${language == 'ar' ? 'text-end':'text-start'} py-3`}> {item.SubDepartmentTitle}</th>
                          <th className={`${language == 'ar' ? 'text-end':'text-start'} py-3`}>
                            <div className='hstack flex gap-3 text-[.9375rem] '>
                              <a aria-label='anchor' role='button' className='ti-btn ti-btn-sm ti-btn-info !rounded-full bg-info-subtle' data-toggle="modal" data-target="#exampleModaledit" onClick={() => setSubdepartmentEdit(item)}><i class='ri-edit-line text-info'></i></a>
                              <a aria-label='anchor' role='button' className='ti-btn ti-btn-sm ti-btn-danger !rounded-full bg-danger-subtle' onClick={() => ShowsubModaldelete(item)}><i class='ri-delete-bin-line text-danger'></i></a>
                            </div>
                          </th>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {/*Add Modal start*/}
      <div className="modal fade right-modal" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-right" role="document">
          <div className="modal-content h-auto">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{modalstate == 0 ? t('Add_dept') : modalstate == 1 ? t('sub_dept') : ''}</h5>
              <button type="button" className={`close ${language == 'ar' ? 'ml-0' : ''}`} data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body d-flex justify-content-center">
              <form className='w-75'>
                <div className={`form-group ${language == 'ar' ? 'text-right' : ''}`}>
                  {InputField(modalstate == 0 ? t('Add_name') : modalstate == 1 ? t('sub_name') : '',
                    'title',
                    'text',
                    modalstate == 0 ? newDepartmentTitle : modalstate == 1 ? subdepartmentData : '',
                    modalstate === 0 ? setNewDepartmentTitle : modalstate === 1 ? setsubdepartmentData : '')}
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-exp" data-dismiss="modal" style={{ fontSize: 14 }} onClick={() => closeModal()}>{t('cls')}</button>
              <button type="button" className="btn btn-wfm" data-dismiss="modal" style={{ fontSize: 14 }} onClick={() => addDepartment()}>{modalstate == 0 ? t('Add_dept') : modalstate == 1 ? t('sub_dept') : ''}</button>
            </div>
          </div>
        </div>
      </div>
      {/*Add Modal end*/}
      {/* Edit Modal */}
      <div className="modal fade right-modal" id="exampleModaledit" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-right" role="document">
          <div className="modal-content h-auto">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{modalstate == 0 ? t('edit_dept') : modalstate == 1 ? t('edit_Subdept') : ''}</h5>
              <button type="button" className={`close ${language == 'ar' ? 'ml-0' : ''}`} data-dismiss="modal" aria-label="Close" onClick={() => closeModal()}>
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body d-flex justify-content-center">
              <form className='w-75'>
                <div className={`form-group ${language == 'ar' ? 'text-right' : ''}`}>
                  {InputField(modalstate == 0 ? t('Add_name') : modalstate == 1 ? t('sub_name') : '',
                    'title',
                    'text',
                    modalstate == 0 ? newDepartmentTitle : modalstate == 1 ? subdepartmentData : '',
                    modalstate === 0 ? setNewDepartmentTitle : modalstate === 1 ? setsubdepartmentData : '')}
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-exp" data-dismiss="modal" style={{ fontSize: 14 }} onClick={() => closeModal()}>{t('cls')}</button>
              <button type="button" className="btn btn-wfm" data-dismiss="modal" style={{ fontSize: 14 }} onClick={DepartmentUpdate}>{modalstate == 0 ? 'Update Department' : modalstate == 1 ? 'Update Sub Department' : ''}</button>
            </div>
          </div>
        </div>
      </div>
      {/* Edit Modal */}
      <CommonModal
    icon={"/images/icons/ask.png"}
    title={`Delete ${modalstate == 0 ? 'Department' : 'Sub Department'} ?`}
    desc={`Are you sure to delete this ${modalstate == 0 ? 'department' : 'sub department'}?`}
    submitText={"Delete"}
    submitAction={() => { modalstate == 0 ? deleteDepartment(editId) : DeleteSubDepartment(subeditId); }}
    setShowModal={setModalView}
    showModal={ModalView}
/>

      <SuccessModal
        icon={modalContent.icon}
        desc={modalContent.desc}
        setShowModal={setModalAddView}
        showModal={ModalAddView}
      />
    </>
  );
};

export default Department;
