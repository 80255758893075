import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import './style/style.css'
const SuccessModal = ({ icon, desc, setShowModal, showModal }) => {


    // icon set based on index
    const icons = [
        {index:0, img:'/images/icons/ask.png'},
        {index:1, img:'/images/icons/checked.png'},
        {index:2, img:'/images/icons/error.png'},
        {index:3, img:'/images/icons/alert.png'},

    ]


    useEffect(() => {
        let timer;
        if (showModal) {
            timer = setTimeout(() => {
                setShowModal(false);
            }, 2000); // Auto-close after 3 seconds
        }
        return () => clearTimeout(timer);
    }, [showModal, setShowModal]);


    return (
        <>
            <Modal show={showModal} onHide={() => setShowModal(false)} className="modelfilter mode mx-auto" size='mdsm'>
                <Modal.Body>
                    <div className='container-fluid d-flex flex-column align-items-center justify-content-center'>
                        <div className='w-100 d-flex justify-content-end'>
                            <i onClick={() => setShowModal(false)} className=" fontsubtitle" aria-hidden="true">X</i>
                        </div>
                        <img src={icons[icon]?.img} style={{ width: '60px', height: '60px', border: "none", borderRadius: '50%' }} />
                        {/* <h3 className="fontmdtitle text-dark1 mt-3">{title}</h3> */}
                        <span className="fontsubtitle font-weight-bold my-4 mt-5 text-dark1 mt-1">{desc}</span>
                        <div className="mt-3 d-flex justify-content-center column-gap-3">
                            {/* <button className="fontsubtitle btn btn-outline-danger rounded-pill px-3 py-1" onClick={() => setShowModal(false)}>Cancel</button> */}
                            <button
                                className="fontsubtitle btn btn-wfm rounded-pill px-3 py-1"
                                style={{minWidth: "100px" }}
                                onClick={() => {
                                    setShowModal(false);
                                }}>OK</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )

}

export default SuccessModal
