import React, { startTransition, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import './style/style.css'
import { useTranslation } from 'react-i18next';

const Sidebar = ({ isVisible, toggleSidebar }) => {
  // const [isDashboardOpen, setIsDashboardOpen] = useState(false);
  const navigate = useNavigate()
  const location = useLocation()
  const [isChartsOpen, setIsChartsOpen] = useState(false);
  const [isActivityOpen, setIsActivityOpen] = useState(false);
  const [isProjectOpen, setIsProjectOpen] = useState(false);
  const [isAttendanceOpen, setIsAttendanceOpen] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('token')))
  const [Role, setRole] = useState(JSON.parse(localStorage.getItem('token'))?.role_access_data)
  const toggleCharts = () => {
    setIsChartsOpen(!isChartsOpen);// Close Dashboard dropdown if open
  };
  const toggleActivity = () => {
    setIsActivityOpen(!isActivityOpen)
  }
  const toggleProject = () => {
    setIsProjectOpen(!isProjectOpen)
  }
  const toggleAttendance = () => {
    setIsAttendanceOpen(!isAttendanceOpen)
  }
  const { t } = useTranslation();
  const toggleDataAttribute = () => {
    const htmlElement = document.documentElement;

    // Toggle the value of the data-toggled attribute
    if (htmlElement.getAttribute('data-toggled') === 'open') {
      htmlElement.setAttribute('data-toggled', 'close');
    } else {
      htmlElement.setAttribute('data-toggled', 'open');
    }
  };

  const handleIconClickvalue = () => {
    if (window.innerWidth < 768) {
      toggleDataAttribute();
    }
  };

  const Sidebar = [
    {
      Name: t('User_Management'),
      img: "/images/icons/UserManage.png",
      route: '0'
    },
    {
      Name: t('Activity_Tracking'),
      img: "/images/icons/UserActivity.png",
      route: '1'
    },
    {
      Name: t('Project_Management'),
      img: "/images/icons/UserProject.png",
      route: '2'
    },
    {
      Name: t('Attendance'),
      img: "/images/icons/attendance.png",
      route: '3'
    },
    {
      Name: t('Timesheet'),
      img: "/images/icons/calendar.png",
      route: '/timesheet'
    },
    // {
    //   Name: 'Subscription',
    //   img: "/images/icons/subscribe.png",
    //   route: '/subscription'
    // }
  ]

  const UserManagement = [
    {
      Name: t('Department'),
      img: "/images/icons/it-department.png",
      route: '/department'
    },
    // {
    //   Name: t('Sub_Department'),
    //   img: "/images/icons/sub_depart.png",
    //   route: '/sub_depart'
    // },
    {
      Name: t('Role_Access'),
      img: "/images/icons/roles_access.png",
      route: '/role-access'
    },
    {
      Name: t('Employee'),
      img: "/images/icons/employee.png",
      route: '/employee'
    },
    // {
    //   Name: 'Screen Interval',
    //   img: "/images/icons/SCRREEN INTERVAL.png",
    //   route: '/screen-interval'
    // },
    {
      Name: t('EmployeeDevice'),
      img: "/images/icons/responsive.png",
      route: '/employee-device'
    },
    {
      Name: t('Schedule'),
      img: "/images/icons/shift.png",
      route: '/schedule'
    },
    {
      Name: t('Calendar'),
      img: "/images/icons/calendar.png",
      route: '/calendar'
    }
  ];

  const Activity = [
    {
      Name: t('Mouse_Keyboard'),
      img: "/images/icons/keyboards.png",
      route: '/keystroke'
    },
    {
      Name: t('Application'),
      img: "/images/icons/activitys.png",
      route: '/application-track'
    },
    {
      Name: t('URL'),
      img: "/images/icons/url.png",
      route: '/Url-track'
    },
    {
      Name: t('Screenshots'),
      img: "/images/icons/captures.png",
      route: '/screen-shots'
    },
    {
      Name: t('Location'),
      img: "/images/icons/locations.png",
      route: '/locations'
    }
  ];
 
  const ProjectManagement = [

    {
      Name: t('Client'),
      img: "/images/icons/client.png",
      route: '/client'
    },
    {
      Name: t('Projects'),
      img: "/images/icons/managment.png",
      route: '/project'
    },
    // {
    //   Name: t('Project_Teams'),
    //   img: "/images/icons/team_project.png",
    //   route: '/add-project'
    // },
    {
      Name: t('To_Dos'),
      img: "/images/icons/todo.png",
      route: '/todo'
    }
  ];

  const Attendance = [
    {
      Name: t('Attendance'),
      img: "/images/icons/attendance.png",
      route: '/attendence'
    },
    // {
    //   Name: t('TimeOff'),
    //   img: "/images/icons/day-off.png",
    //   route: '/time-off'
    // },
  ];

  useEffect(() => {
    const matchedRoute = UserManagement.some(item => item.route === location.pathname);
    const isActivityRoute = Activity.some(item => item.route === location.pathname);
    const isProjectRoute = ProjectManagement.some(item => item.route === location.pathname);

    setIsChartsOpen(matchedRoute);
    setIsActivityOpen(isActivityRoute);
    setIsProjectOpen(isProjectRoute);
  }, [location.pathname]);

  return (
    <aside className={`app-sidebar ${isVisible ? 'sidebar-open' : ''}`} id="sidebar">
      <div className="main-sidebar-header">
        <a className="header-logo">
          <img
            src="/assets/images/brand-logos/kefa'ah.png"
            // src="/images/logos/Artboard 2.png"
            alt="logo"
            className="desktop-logo"
            // style={{ width: "110px", height: "95px" }}
            style={{ width: "150px", height: "56px" }}
          />
          <img
            src="../assets/images/brand-logos/toggle-logo.png"
            alt="logo"
            className="toggle-logo"
          />
          <img
            src="../assets/images/brand-logos/desktop-dark.png"
            alt="logo"
            className="desktop-dark"
          />
          <img
            src="../assets/images/brand-logos/toggle-dark.png"
            alt="logo"
            className="toggle-dark"
          />
          <img
            src="../assets/images/brand-logos/desktop-white.png"
            alt="logo"
            className="desktop-white"
          />
        </a>
      </div>
      <div className="main-sidebar pt-3" id="sidebar-scroll">
        <div className="w-100 d-flex justify-content-between px-3 py-1">
          <span className="side-menu__label">Hi, {user?.name}</span>
          <a className="d-block d-md-none d-lg-none" onClick={toggleSidebar}><i class="ri ri-close-large-fill" aria-hidden="true"></i></a>
        </div>
        <nav className="main-menu-container nav nav-pills flex-column sub-open">
          <div className="slide-left" id="slide-left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#7b8191"
              width={24}
              height={24}
              viewBox="0 0 24 24"
            >
              <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
            </svg>
          </div>
          <ul className="main-menu">
            <li className="slide" onClick={() => {
              startTransition(() => {
                navigate('/dashboard')
              })
            }} >
              <a className={`side-menu__item ${location.pathname == '/dashboard' ? "side_menu_active" : ""} gap-3`} onClick={() => { handleIconClickvalue() }}>
                <img src='/images/icons/dashboard.png' style={{ width: 18, height: 18 }} alt="Employee" />
                <span className={`txt-sidebar`} style={{ color: location.pathname == '/dashboard' ? '#000000' : '' }}> {t('dashboard')}</span>
              </a>
            </li>

            {Sidebar.map((item, index) => (
              <li className="slide" key={index}>
                {UserManagement.find((item, index) => Role?.menu?.includes(item.route)) && item.route == "0" ? (
                  <>
                    <a
                      className="side-menu__item"
                      onClick={() => toggleCharts()}
                    >
                      <img src="/images/icons/UserActivity.png" className="mr-2" alt="" style={{ width: 22, height: 22 }} />
                      <span className=" ml-2 txt-sidebar">{item.Name}</span>
                      <i className={`fe fe-chevron-right txt-sidebar side-menu__angle ${isChartsOpen ? 'rotateZ' : ''}`} />
                    </a>
                    <ul className={`slide-menu ${isChartsOpen ? 'd-block' : 'd-none'} child1`}>
                      <li className="slide side-menu__label1">
                        <a>Charts</a>
                      </li>
                      {UserManagement.filter((data, index) => Role?.menu?.includes(data.route)).map(item => (
                        <li
                          className="slide"
                          onClick={() => {
                            startTransition(() => {
                              navigate(item.route)
                            })
                          }}>
                          <a
                            className={`side-menu__item ${location.pathname == item.route ? "side_menu_active" : ""} gap-3`}
                            onClick={() => { handleIconClickvalue() }}>
                            <img src={item.img} style={{ width: 25, height: 25 }} alt="Employee" />
                            <span className={`txt-sidebar`} style={{ color: location.pathname == item.route ? '#000000' : '' }}>{item.Name}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : Activity.find((item, index) => Role?.menu?.includes(item.route)) && item.route == "1" ? (
                  <>
                    <a className="side-menu__item" onClick={() => toggleActivity()}>
                      <img src="/images/icons/ActivityUser.png" className="mr-2" alt="" style={{ width: 22, height: 22 }} />
                      <span className="ml-2 txt-sidebar">{item.Name}</span>
                      <i className={`fe fe-chevron-right txt-sidebar side-menu__angle ${isActivityOpen ? 'rotateZ' : ''}`} />
                    </a>
                    <ul className={`slide-menu ${isActivityOpen ? 'd-block' : 'd-none'} child1`}>
                      <li className="slide side-menu__label1">
                        <a >Charts</a>
                      </li>

                      {Activity.filter((data, index) => Role?.menu?.includes(data.route)).map(item => (
                        <li className="slide" onClick={() => {
                          startTransition(() => {
                            navigate(item.route)
                          })
                        }}>
                          <a className={`side-menu__item ${location.pathname == item.route ? "side_menu_active" : ""} gap-3`} onClick={() => { handleIconClickvalue() }}>
                            <img src={item.img} style={{ width: 22, height: 22 }} alt="Employee" />
                            <span className={`txt-sidebar`} style={{ color: location.pathname == item.route ? '#000000' : '' }}>{item.Name}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : ProjectManagement.find((item, index) => Role?.menu?.includes(item.route)) && item.route == "2" ? (
                  <>
                    <a className="side-menu__item" onClick={() => toggleAttendance()}>
                      <img src="/images/icons/UserProject.png" className="mr-2" alt="" style={{ width: 22, height: 22 }} />
                      <span className="ml-2 txt-sidebar">{item.Name}</span>
                      <i className={`fe fe-chevron-right txt-sidebar side-menu__angle ${isAttendanceOpen ? 'rotateZ' : ''}`} />
                    </a>
                    <ul className={`slide-menu ${isAttendanceOpen ? 'd-block' : 'd-none'} child1`}>
                      <li className="slide side-menu__label1">
                        <a >Charts</a>
                      </li>

                      {ProjectManagement.filter((data, index) => Role?.menu?.includes(data.route)).map(item => (
                        <li className="slide" onClick={() => {
                          startTransition(() => {
                            navigate(item.route)
                          })
                        }}>
                          <a className={`side-menu__item ${location.pathname == item.route ? "side_menu_active" : ""} gap-3`} onClick={() => { handleIconClickvalue() }}>
                            <img src={item.img} style={{ width: 22, height: 22 }} alt="Employee" />
                            <span className={`txt-sidebar`} style={{ color: location.pathname == item.route ? '#000000' : '' }}>{item.Name}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : Attendance.find((item, index) => Role?.menu?.includes(item.route)) && item.route == "3" ? (
                  <>
                    <a className="side-menu__item" onClick={() => toggleProject()}>
                      <img src="/images/icons/UserProject.png" className="mr-2" alt="" style={{ width: 22, height: 22 }} />
                      <span className="ml-2 txt-sidebar">{item.Name}</span>
                      <i className={`fe fe-chevron-right txt-sidebar side-menu__angle ${isProjectOpen ? 'rotateZ' : ''}`} />
                    </a>
                    <ul className={`slide-menu ${isProjectOpen ? 'd-block' : 'd-none'} child1`}>
                      <li className="slide side-menu__label1">
                        <a >Charts</a>
                      </li>

                      {Attendance.filter((data, index) => Role?.menu?.includes(data.route)).map(item => (
                        <li className="slide" onClick={() => {
                          startTransition(() => {
                            navigate(item.route)
                          })
                        }}>
                          <a className={`side-menu__item ${location.pathname == item.route ? "side_menu_active" : ""} gap-3`} onClick={() => { handleIconClickvalue() }}>
                            <img src={item.img} style={{ width: 22, height: 22 }} alt="Employee" />
                            <span className={`txt-sidebar`} style={{ color: location.pathname == item.route ? '#000000' : '' }}>{item.Name}</span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </>
                ) : Sidebar.find(data => Role?.menu?.includes(data.route) && item.route == data.route) && (
                  <a
                    onClick={() => {
                      handleIconClickvalue(); // Toggle the attribute
                      startTransition(() => {
                        navigate(item.route); // Then navigate
                      });
                    }} className={`side-menu__item ${location.pathname == item.route ? "side_menu_active" : ""} gap-3`}>
                    <img src={item.img} style={{ width: 22, height: 22 }} alt={item.Name} />
                    <span className={`txt-sidebar ${location.pathname == item.route ? 'active' : ''}`}>{item.Name}</span>
                  </a>
                )}
              </li>
            ))}

          </ul>
          <div className="slide-right" id="slide-right">
            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width={24} height={24} viewBox="0 0 24 24">
              <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
            </svg> */}
          </div>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
