const APIBASE_URL = "http://31.220.54.38:8000/";
// const APIBASE_URL = "http://127.0.0.1:8000/";
// const APIBASE_URL = "http://13.238.143.13:8000/";

module.exports = {

  BASE_URL: APIBASE_URL,
  //Login
  CLIENT_LOGIN: APIBASE_URL + 'employee/admin_login',
  // http://31.220.54.38:8000/employee/admin_login



  CLIENT_LIST: APIBASE_URL + 'client/list',
  CLIENT_CREATE: APIBASE_URL + 'client/create',
  CLIENT_DELETE: APIBASE_URL + 'client/delete',
  CLIENT_UPDATE: APIBASE_URL + 'client/update',


  LIST_ORGANIZATION: APIBASE_URL + 'organization/list',
  LIST_SCREENSHOT: APIBASE_URL + 'screen/list',
  LIST_ACTIVITY: APIBASE_URL + 'activity_tracker/list',
  FILTER_EMPLOYEE: APIBASE_URL + 'activity_tracker/list_filter_employee',
  FILTER_SCREENSHOT: APIBASE_URL + 'list_filter_screen',


  ADD_DEPARTMENT: APIBASE_URL + 'depart/department/create',
  LIST_DEPARTMENT: APIBASE_URL + 'depart/department/list',
  DELETE_DEPARTMENT: APIBASE_URL + 'depart/department/delete',
  LIST_SUBDEPARTMENT: APIBASE_URL + 'sub_depart/sub_departments',
  DELETE_SUBDEPARTMENT: APIBASE_URL + 'sub_depart/delete/',
  ADD_SUBDEPARTMENT: APIBASE_URL + 'sub_depart/sub_departments/create',
  LIST_ROLE: APIBASE_URL + 'role_access/list',
  ADD_ROLE: APIBASE_URL + 'role_access/create',
  DELETE_ROLE: APIBASE_URL + 'role_access/delete/',
  REPORT_PERSON1: APIBASE_URL + 'employee/list_depart',
  REPORT_PERSON2: APIBASE_URL + 'employee/list_subdepart',
  GET_SUBDEPT_BYDEPT: APIBASE_URL + 'sub_depart/sub_depart_list',

  ADD_Employee: APIBASE_URL + 'employee/create',
  LIST_EMPLOYEE: APIBASE_URL + 'employee/list',
  ALL_LIST_EMPLOYEE: APIBASE_URL + 'employee/listall',
  DELETE_EMPLOYEE: APIBASE_URL + 'employee/delete',

  EDITEMPLOYEE: APIBASE_URL + 'employee/employee_by/',
  UPDATEEMPLOYEE: APIBASE_URL + 'employee/update/',

  ADD_DESIGNATION: APIBASE_URL + 'roles/create',
  LIST_DESIGNATION: APIBASE_URL + 'roles/list',
  DELETE_DESIGNATION: APIBASE_URL + 'roles/designation/delete',



  LIST_LOCATION: APIBASE_URL + 'location/list',
  LIST_REPORT: APIBASE_URL + 'employee/list_reporting',


  LIST_PROJECT: APIBASE_URL + 'project/list',
  ADD_PROJECT: APIBASE_URL + 'project/create',
  UPDATE_PROJECT: APIBASE_URL + 'project/update',
  DELETE_PROJECT: APIBASE_URL + 'project/delete',


  LIST_ADD_PROJECT: APIBASE_URL + 'add_project/list',
  DELETE_ADD_PROJECT: APIBASE_URL + 'add_project/delete',
  CREATE_ADD_PROJECT: APIBASE_URL + 'add_project/create',
  UPDATE_ADD_PROJECT: APIBASE_URL + 'add_project/update',


  LIST_SCREEN_INTERVAL: APIBASE_URL + 'screenshot_interval/list',
  ADD_SCREEN_INTERVAL: APIBASE_URL + 'screenshot_interval/create',
  UPDATE_SCREEN_INTERVAL: APIBASE_URL + 'screenshot_interval/update/',
  DELETE_SCREEN_INTERVAL: APIBASE_URL + 'screenshot_interval/delete/',

  UPDATEDEPARTMENT: APIBASE_URL + 'depart/update/',
  UPDATESUBDEPARTMENT: APIBASE_URL + 'sub_depart/update/',
  UPDATEROLE: APIBASE_URL + 'role_access/update/',

  TODOCREATE: APIBASE_URL + 'todo/create',
  TODO_UPDATE: APIBASE_URL + 'todo/update/',
  TODO_LIST: APIBASE_URL + 'todo/list',
  TODO_DELETE: APIBASE_URL + 'todo/delete/',
  CREATECATEGORY: APIBASE_URL + 'todo_category/create',
  LISTCATEGORY: APIBASE_URL + 'todo_category/list',
  CATEGORY_DELETE: APIBASE_URL + 'todo_category/delete/',
  PROJECT_TODOLIST: APIBASE_URL + 'todo/list_project',
  PROJECT_TODO_EMPLOYEE_LIST:APIBASE_URL+'timesheets/list',

  LIST_EMPLOYEEDEVICE: APIBASE_URL + 'employee_device/list',
  DELETE_EMPLOYEEDEVICE: APIBASE_URL + 'employee_device/delete/',
  ADDDEVICE: APIBASE_URL+'employee_device/create',

  MESSAGE_CREATE: APIBASE_URL + 'project_discussion/create',
  MESSAGE_LIST: APIBASE_URL + 'project_discussion/list',
  BUDGET_LIST: APIBASE_URL + 'timesheets/list_budget?project_id=',


  LISTTIMEOFF : APIBASE_URL + 'timeoff/list',
  CREATETIMEOFF:APIBASE_URL+'timeoff/create',

  LISTSUBSCRIPTION : APIBASE_URL + 'api/subscriptions',
  ADDSUBSCRIPTIONPLAN : APIBASE_URL + 'api/subscriptions/create',
  DELETESUBSCRIPTIONPLAN : APIBASE_URL + 'api/subscriptions/delete/',
  EDITSUBSCRIPTIONPLAN : APIBASE_URL + 'api/subscriptions/update/',
 

  LISTSCHEDULE : APIBASE_URL + 'scheduling/list',
 
  DELETE_SCHEDULE : APIBASE_URL +'scheduling/delete/',
  CREATE_SCHEDULE : APIBASE_URL + 'scheduling/create',
  
  EMPLOYEESCHEDULING : APIBASE_URL + 'employee_scheduling/list',
  CREATEEMPLOYEESCHEDULE : APIBASE_URL + 'employee_scheduling/create',

  SYNCEMP:APIBASE_URL+'employee/sync_emp',
  SYNCDEPARTMENT :APIBASE_URL +'depart/department/sync',

  ADMIN_LOGIN :APIBASE_URL +'customer/admin/login',
  CHECK_LICENSE :APIBASE_URL +'customer/check_license',

  LIST_CALENDAR : APIBASE_URL + 'events/list',
  ADD_CALENDAR : APIBASE_URL + 'events/create',
  UPDATE_CALENDAR : APIBASE_URL + 'events/update/',


}