import React, { useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import './style/style.css'
const CommonModal = ({ icon, title, desc, submitText, submitAction, setShowModal, showModal,cancel }) => {


    // useEffect(() => {
    //     let timer;
    //     if (!submitAction && showModal) {
    //       timer = setTimeout(() => {
    //         setShowModal(false);
    //       }, 3000); // 3 seconds timer
    //     }
    //     return () => clearTimeout(timer); // Clear timer on cleanup
    //   }, [submitAction, showModal, setShowModal]);


    return (
        <>
            <Modal show={showModal} onHide={() => setShowModal(false)} className="modelfilter mode mx-auto" size='mdsm'>
                <Modal.Body>
                    <div className='container-fluid d-flex flex-column align-items-center justify-content-center'>
                        <div className='w-100 d-flex justify-content-end'>
                            <i onClick={() => setShowModal(false)} role='button' className=" fontsubtitle" aria-hidden="true">x</i>
                        </div>
                        <img src={icon} style={{ width: '60px', height: '60px', border: "none", borderRadius: '50%' }} />
                        {/* <h3 className="fontmdtitle text-dark1 mt-3">{title}</h3> */}
                        <span className="fontsubtitle font-weight-bold my-4 mt-5 text-dark1 mt-1">{desc}</span>
                        <div className="mt-3 d-flex justify-content-center column-gap-3">
                            {/* <button className="fontsubtitle btn btn-outline-danger rounded-pill px-3 py-1" onClick={() => setShowModal(false)}>Cancel</button> */}
                            {submitAction && (
                                <button
                                    className="fontsubtitle btn btn-exp rounded-pill px-3 py-1"
                                    style={{ width: "100px" }}
                                    onClick={() => {setShowModal(false);cancel()}}>
                                    Cancel</button>
                            )}
                            <button
                                className="fontsubtitle btn btn-wfm rounded-pill px-3 py-1"
                                style={{minWidth: "100px" }}
                                onClick={() => {
                                    if (submitAction) {
                                        submitAction();
                                    }
                                    setShowModal(false);
                                }}>{submitText}</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )

}

export default CommonModal
