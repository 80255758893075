import React, { useState } from 'react'

const PagesHeadings = ({PageHeader,insidehead}) => {
    const [language, setlanguage] = useState(localStorage.getItem('lang'))
  return (
    <div className="md:flex block items-center justify-between mb-1 mt-[2rem]  page-header-breadcrumb">
        <div className="my-auto">

          <h5 className={`page-title text-[1.3125rem] font-weight-bold text-defaulttextcolor mb-0 ${language == 'ar' ? 'text-right' : ''}`}>
            {PageHeader}
          </h5>
          <nav>

            <ol className="flex items-center whitespace-nowrap min-w-0 pl-0">

              <li className="text-[12px]">

                <a className="flex items-center text-primary hover:text-primary"
                  href="javascript:void(0);">
                  {insidehead}
                  <i className="ti ti-chevrons-right flex-shrink-0 mx-3 overflow-visible text-textmuted rtl:rotate-180" />
                </a>
              </li>
              <li className="text-[12px]">
                <a
                  className="flex items-center text-textmuted"
                  href="javascript:void(0);"
                >
                  {PageHeader}
                </a>
              </li>
            </ol>
          </nav>
        </div>
      </div>
  )
}

export default PagesHeadings