import React, { Suspense, useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import Header from "./common/Header";
import { Shimmer } from "./common/Shimmer";
import 'react-loading-skeleton/dist/skeleton.css';
import Sidebar from "./common/Sidebar";
import useLocalStorage from "./utils/RedirectRoute";
import 'react-image-crop/dist/ReactCrop.css'
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { t } from "i18next";
import SuccessModal from "./common/SuccessModal";

const Login = React.lazy(() => import("./common/Login"));
const Content = React.lazy(() => import("./split/Dashboard/Content"));
const ScreenShots = React.lazy(() => import("./split/ScreenShot/Screenshots"));
const Application_act = React.lazy(() => import("./split/Application_activity"));
const Organization = React.lazy(() => import("./split/Organization"));
const URL_Track = React.lazy(() => import("./split/Url-Track"));
const Organization_details = React.lazy(() => import("./split/Organization_details"));
const Attendence = React.lazy(() => import("./split/Attendence/Attendence"));
const TimeOff = React.lazy(() => import("./split/Attendence/TimeOff"));
const Department = React.lazy(() => import("./split/Department/Department"));
const Designation = React.lazy(() => import("./split/Designation/Designation"));
const Employee = React.lazy(() => import("./split/Employee/Employee"));
const Locations = React.lazy(() => import("./split/Locations/Locations"));
const Timesheet = React.lazy(() => import("./split/Timesheet/Timesheet"));
const Keystoke = React.lazy(() => import("./split/Keystroke/Keystoke"));
const to_do_list = React.lazy(() => import("./split/ToDo-List/ToDoList"));
const sub_depart = React.lazy(() => import("./split/Department/SubDepartment"));
const Project_Management = React.lazy(() => import("./split/Project_Management/Project_Management"));
const Add_project = React.lazy(() => import("./split/Project_Management/Add_project"));
const Client = React.lazy(() => import("./split/Project_Management/Client"));
const Screen_interval = React.lazy(() => import("./split/screen_interval/Screen_interval"));
const TODO = React.lazy(() => import("./split/TODO"));
const RoleAccess = React.lazy(() => import("./split/Department/RoleAccess"));
const EmployeeDevice = React.lazy(() => import('./split/Employee/EmployeeDevice'))
const ProjectDetails = React.lazy(() => import("./split/ProjectDetails/ProjectDetails"));
const Calendar = React.lazy(() => import("./split/Calendar/Calendar.js"));
const Subscription = React.lazy(()=> import("./split/Subscription/Subscription"))
const Schedule = React.lazy(()=> import("./split/Employee/Scheduling.js"))

const App = () => {
  const [token, setToken] = useLocalStorage("token");
  const [data, setData] = useState(false);
  // var routeList = JSON.parse(localStorage.getItem("token")?.role_access_data?.menu)
  const lang = localStorage.getItem("lang");



  let routeList = [];
  try {
    const parsedToken = token;
    routeList = parsedToken?.role_access_data?.menu || [];
  } catch (error) {
    console.error("Failed to parse token:", error);
  }

  // Adding login and root paths to routeList
  routeList = [...routeList, "/login", "/", "/*", "/dashboard", "/project-details"];

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [filterValue, setFilterValue] = useState(''); // State for filter value

  // Toggle sidebar visibility
  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };
  const handleFilter = (value) => {
    console.log(value); // Log the value
    setFilterValue(value); // Set the filter value in state
  };
  const [search, setSearch] = useState();
  const Dashboard = (Component) => {

    if (!token) {
      return <Navigate to="/login" />
    }
    
    return (
      <div className="page">
        <Header toggleSidebar={toggleSidebar} search={search} setSearch={setSearch} />
        <Sidebar isVisible={isSidebarVisible} toggleSidebar={toggleSidebar} />
        <div className="content">
          <div className="main-content ">
            <Component search={search} setSearch={setSearch} filters={filterValue} setFilters={handleFilter}/>
          </div>
        </div>
      </div>
    );
  };

  const LoginSecure = (Component) => {
    const Token = token;
    if (Token?.id) {
      return <Navigate to="/dashboard" />;
    }
    return <Component />;
  };

  const routes = [

    { path: "/login", element: LoginSecure(Login) },
    { path: "/", element: LoginSecure(Login) },
    { path: "/dashboard", element: Dashboard(Content) },
    { path: "/screen-shots", element: Dashboard(ScreenShots) },
    { path: "/application-track", element: Dashboard(Application_act) },
    { path: "/todo", element: Dashboard(TODO) },
    { path: "/organization", element: Dashboard(Organization) },
    { path: "/Url-track", element: Dashboard(URL_Track) },
    { path: "/organization_details", element: Dashboard(Organization_details) },
    { path: "/attendence", element: Dashboard(Attendence) },
    { path: "/time-off", element: Dashboard(TimeOff) },
    { path: "/department", element: Dashboard(Department) },
    { path: "/designation", element: Dashboard(Designation) },
    { path: "/employee", element: Dashboard(Employee) },
    { path: "/locations", element: Dashboard(Locations) },
    { path: "/timesheet", element: Dashboard(Timesheet) },
    { path: "/keystroke", element: Dashboard(Keystoke) },
    { path: "/todo-list", element: Dashboard(to_do_list) },
    { path: "/sub_depart", element: Dashboard(sub_depart) },
    { path: "/role-access", element: Dashboard(RoleAccess) },
    { path: "/project", element: Dashboard(Project_Management) },
    { path: "/client", element: Dashboard(Client) },
    { path: "/screen-interval", element: Dashboard(Screen_interval) },
    { path: "/add-project", element: Dashboard(Add_project) },
    { path: "/employee-device", element: Dashboard(EmployeeDevice) },
    { path: "/project-details", element: Dashboard(ProjectDetails) },
    { path: "/subscription", element:Dashboard(Subscription)},
    { path: "/calendar", element:Dashboard(Calendar)},
    { path: "/schedule", element:Dashboard(Schedule)},
    { path: "/*", element: <Navigate to="/dashboard" /> }
  ];

  const filteredRoutes = routes.filter(route => routeList.includes(route.path));
  console.log("ROutes==>", filteredRoutes);

  const LoaderDash = () => {
    const location = useLocation();
    if (location.pathname != '/dashboard' || location.pathname != '/login') {
      return <Shimmer />;
    }
  };

  return (
    <Router>
      <ToastContainer />
      <SuccessModal />
      <Routes>
        {filteredRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
    </Router>
  );
};

export default App;
